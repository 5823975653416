@keyframes fadein {
    from {
        opacity: 0;
        z-index: 0;
        transform: scale3d(0.8);
    }
    to {
        opacity: 1;
        z-index: 10;
        transform: scale3d(1);
    }
}

.container {
    height: 100vh;
    height: 100svh;
    width: 100vw;
    position: relative;
    z-index: 12;
    animation: fadein 2s ease-in-out 0s 1 normal forwards;
}

.text-container {
    color: var(--white);
    text-transform: uppercase;
    letter-spacing: 4px;
    gap: 10vh;
    user-select: none;
    -webkit-user-select: none;
}

.text-container h1, h2, h3, h4, h5, h6 {
    text-align: center;
}

.text-container > div:nth-child(3) > h5 {
    cursor: pointer;
}