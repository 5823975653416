@keyframes fadein {
  from {
    opacity: 0;
    z-index: 0;
    transform: scale3d(0.8);
  }
  to {
    opacity: 1;
    z-index: 10;
    transform: scale3d(1);
  }
}

.container {
  height: 100vh;
  height: 100svh;
  width: 100vw;
  animation: fadein 2s ease-in-out 0s 1 normal forwards;
}

.text-container {
  color: var(--white);
  text-transform: uppercase;
  letter-spacing: 4px;
  /* gap: 20vh; */
  width: 90vw;
  height: 80vh;
  user-select: none;
  -webkit-user-select: none;
}

.text-container h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
}

.text-container > div:nth-child(2) > h5 {
  cursor: pointer;
}

.title-and-quote {
  gap: 10vh;
}

.form-container {
  width: -moz-fit-content;
  width: fit-content;
}

input {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 8px;
  height: 40px;
  color: var(--white);
}

input,
label {
  margin: 10px 0px;
  padding: 10px 10px;
}

button {
  background-color: transparent;
  border: none;
  color: var(--white);
  font-size: inherit;
}

@media screen and (max-width: 479px) {
  .title-and-quote {
    gap: 5vh;
  }
  .title-and-quote > div:nth-child(1) > img {
    display: none;
  }
  .quote-container {
    width: 70vw;
    height: 20vh;
    height: 20svh;
    text-transform: none;
  }
  .text-container {
    height: 54vh;
    height: 54svh;
  }
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  input {
    height: 21.93px;
    width: 165.87px;
    border-radius: 3.94px;
  }
}
