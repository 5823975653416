.footer {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  position: relative;
  min-height: 253px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.line {
  display: block;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  flex-direction: row;
}

.logoSection {
  /* flex: 1; */
  position: absolute;
  left: 20px;
  display: flex;
  justify-content: center;
}

.logo {
  width: 50px;
  height: 50px;
}

.menuSection {
  flex: 1;
  display: flex;
  justify-content: end;
  width: 99%;
  align-items: center;
  gap: 98px;
}

.menuItem {
  cursor: pointer;
}

.socialSection {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.socialIcon {
  cursor: pointer;
}

.icon {
  width: 30px;
  height: 30px;
}

.copyright {
  width: 100%;
  border-top: 1px solid #444;
  padding-top: 10px;
  font-size: 12px;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
  .logoSection {
    /* flex: 1; */
    position: static;
    display: flex;
    justify-content: center;
  }
  .container {
    flex-direction: column;
    gap: 20px;
  }
  .line {
    display: none;
  }
  .menuSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 99%;
    align-items: center;
    gap: 12px;
  }
  .socialSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

@media screen and (max-width: 479px) {
}
