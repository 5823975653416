@keyframes fadein {
  from {
    opacity: 0;
    z-index: 0;
    transform: scale3d(0.8);
  }
  to {
    opacity: 1;
    z-index: 1000;
    transform: scale3d(1);
  }
}

.container {
  height: 100vh;
  height: 100svh;
  width: 100vw;
  position: fixed;
  z-index: 999;
  justify-content: end;
}

.download_modal_bg {
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 39%,
      rgba(253, 29, 29, 0) 50%,
      rgba(0, 0, 0, 1) 63%
    ),
    url("../../assets/download_modal_bg.webp");
  animation: fadein 2s ease-in-out 0s 1 normal forwards;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  animation: fadein 1s ease-in-out 0s 1 normal forwards;
}

.download_form_modal_bg {
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 39%,
      rgba(253, 29, 29, 0) 50%,
      rgba(0, 0, 0, 1) 63%
    ),
    url("../../assets/download_form_modal_bg.webp");
  animation: fadein 2s ease-in-out 0s 1 normal forwards;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  animation: fadein 1s ease-in-out 0s 1 normal forwards;
}

.text-container {
  color: var(--white);
  text-transform: uppercase;
  letter-spacing: 4px;
  user-select: none;
  -webkit-user-select: none;
  width: 33%;
  padding: 5%;
}

.text-container-gap {
  gap: 6vh;
}

.text-container h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
}

.text-container p {
  text-transform: none;
}

.text-container > div:nth-child(3) > h5 {
  cursor: pointer;
}

.close-button-container {
  position: fixed;
  top: 40px;
  right: 20px;
}

.close-button-container img {
  width: 24px;
}

.back-button-container {
  position: fixed;
  top: 40px;
  left: 20px;
}

.back-button-container img {
  width: 30px;
}

.confirmation-modal-container {
  position: fixed;
  top: 50vh;
  left: 50vw;
  width: 90vw;
  height: 45vh;
  background: rgba(16, 16, 16, 0.72);
  transform: translate(-50%, -50%);
  color: var(--white);
  animation: fadein 1s ease-in-out 0s 1 normal forwards;
  /* backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(1px); */
  text-transform: uppercase;
  letter-spacing: 4px;
  text-align: center;
  padding-bottom: 6%;
  opacity: 1;
  transition: opacity 700ms ease;
  z-index: 888;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.input {
  background-color: transparent;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  height: 40px;
  color: var(--white);
}

.input,
h2 {
  margin: 10px 0px;
  padding: 10px 10px;
}

.limited-period-offer {
  background: linear-gradient(90deg, #990000 0%, #330000 100%);
  height: 44px;
  width: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 767px) {
  .download_modal_bg {
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 15%,
        rgba(253, 29, 29, 0) 70%
      ),
      url("../../assets/download_modal_bg.webp");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    animation: fadein 1s ease-in-out 0s 1 normal forwards;
  }
  .download_form_modal_bg {
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 15%,
        rgba(253, 29, 29, 0) 70%
      ),
      url("../../assets/download_form_modal_bg.webp");
    background-position: 12%;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: start;
    animation: fadein 500ms ease-in-out 0s 1 normal forwards;
  }
  .text-container {
    width: 80%;
  }
  .input {
    height: 21.93px;
    width: 165.87px;
    border-radius: 3.94px;
  }
  .text-container-gap {
    gap: 5vh;
  }
  .confirmation-modal-container {
    width: 100vw;
    height: 30vh;
    height: 30svh;
    padding-left: 10px;
    padding-right: 10px;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
  .limited-period-offer {
    height: 24px;
    width: 100%;
  }
}
