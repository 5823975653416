@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  padding: 40px 40px;
  position: fixed;
  top: 0px;
  background: transparent;
  width: 100vw;
  z-index: 14;
  animation: fadein 2s ease-in-out 0s 1 normal forwards;
  justify-content: space-between;
}

a {
  text-decoration: inherit;
  color: inherit;
}

.brand-container {
  padding: 10px;
  cursor: pointer;
}

.nav-links-container {
  gap: 40px;
  color: var(--white);
}

.nav-link-item {
  text-transform: uppercase;
  gap: 2px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

@keyframes active_link_animation {
  from {
    transform: translate(-100%, 200px);
  }
  to {
    transform: translate(0%, 0%);
  }
}

.nav-link-item > div:nth-child(2) {
  height: 2px;
  width: 45%;
  background-color: transparent;
}

.nav-link-item.active > div:nth-child(2) {
  background-color: white;
  animation: active_link_animation 1400ms ease-out 0s 1 normal forwards;
}

.background-img {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  object-fit: cover;
  z-index: -1;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  .container {
    padding: 30px 20px;
    justify-content: end;
  }
  .nav-links-container {
    gap: 10px;
    color: var(--white);
  }
  .brand-container {
    display: none !important;
  }
  .nav-link-item > div:nth-child(2) {
    height: 1px;
    width: 45%;
    background-color: transparent;
  }
}
