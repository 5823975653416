:root {
  --spiral-image-animation-duration: 3s;
  --eye-image-animation-duration: 3s;
  --h1-animation-duration: 3s;
  --animation-timing-function: cubic-bezier(0.68, 0.65, 0.43, 0.89);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  height: 100vh;
  height: 100svh;
  width: 100vw;
  background-color: black;
  color: #aeaeae;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  animation-name: fadein;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.img_spiral {
  width: 40vw;
  height: 40vw;
  /* border: 1px solid red; */
  transition: transform 1s ease-in-out;
  z-index: 3;
}

.img_spiral:hover {
  cursor: pointer;
}

.img_blue_eye {
  display: none;
  width: 70%;
  transform: scale(5);
}

@keyframes home_spiral {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
    display: block;
  }
  50% {
    transform: scale(0.2) rotate(360deg);
    opacity: 1;
  }
  100% {
    transform: scale(6) rotate(0deg);
    opacity: 0;
  }
}

.img_spiral.active {
  /* animation: home_spiral var(--spiral-image-animation-duration) ease-in-out 0s 1 normal forwards; */
  animation: home_spiral var(--spiral-image-animation-duration)
    var(--animation-timing-function) 0s 1 normal forwards;
}

.img_spiral.active_removed {
  transform: scale(2) rotate(0deg);
}

@keyframes home_eye_animation_1 {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(0.5);
    opacity: 1;
  }
}

@keyframes home_eye_animation_2 {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(2);
  }
}

.img_blue_eye.active_animation_1 {
  display: block;
  /* animation: home_eye_animation_1 var(--eye-image-animation-duration) ease-in 0s 1 normal forwards; */
  animation: home_eye_animation_1 var(--eye-image-animation-duration)
    var(--animation-timing-function) 0s 1 normal forwards;
}

.img_blue_eye.active_animation_2 {
  /* animation: home_eye_animation_2 var(--h1-animation-duration) ease-in 0s 1 normal forwards; */
  animation: home_eye_animation_2 var(--h1-animation-duration)
    var(--animation-timing-function) 0s 1 normal forwards;
}

.h1 {
  position: fixed;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* left: 50%;
  transform: translate(-50%, -50%); */
  color: var(--white);
  width: 100%;
  text-align: center;
  transform: translate(0%, -50%) scale(1);
  opacity: 0;
  z-index: -1;
  background-image: url('../../assets/h1_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 314px;
  /* background: radial-gradient(55.64% 32.97% at 50% 44.29%, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0.308977) 0.01%, rgba(39, 39, 39, 0.175232) 54.87%, rgba(89, 89, 89, 0) 100%); */
  /* text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); */
  height: 314px;
  /* height: 100vh;
  height: 100svh; */
  text-transform: uppercase;
  /* transition: transform var(--h1-animation-duration) ease-in-out, opacity var(--h1-animation-duration) ease-in-out; */
  transition: transform var(--h1-animation-duration)
      var(--animation-timing-function),
    opacity var(--h1-animation-duration) var(--animation-timing-function);
}

.h1.active {
  transform: translate(0%, -50%) scale(1);
  opacity: 1;
  z-index: 2;
}

@keyframes fadeout {
  from {
    opacity: 1;
    z-index: 1;
  }
  to {
    opacity: -1;
  }
}

@keyframes scale_down {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 1000px);
  }
}

.scale-down {
  animation: scale_down 10s ease 100ms 1 normal forwards;
}

.touch-anywhere {
  /* display: none; */
  z-index: 999;
}

.fade_out {
  animation: fadeout 2s ease-in-out 0s 1 normal forwards !important;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  .img_spiral {
    width: 100vw;
    height: max-content;
  }
  .touch-anywhere {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10vh;
    width: 100%;
  }
}
