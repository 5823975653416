@keyframes fadein {
  from {
    opacity: 0;
    z-index: 0;
    transform: scale3d(0.8);
  }
  to {
    opacity: 1;
    z-index: 10;
    transform: scale3d(1);
  }
}

.container {
  height: 100vh;
  height: 100svh;
  width: 100vw;
  animation: fadein 2s ease-in-out 0s 1 normal forwards;
}

.text-container {
  color: var(--white);
  text-transform: uppercase;
  letter-spacing: 4px;
  /* gap: 20vh; */
  width: 90vw;
  height: 80vh;
  user-select: none;
  -webkit-user-select: none;
}

.text-container h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  margin-bottom: 0px;
}

.text-container > div:nth-child(3) > h5 {
  cursor: pointer;
}

.title-and-quote {
  gap: 10vh;
}

.quote-container {
  width: 60vw;
  height: 40vh;
  text-transform: none;
}

@keyframes animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.quote-container > h5 {
  animation: animation 900ms ease 0s 1 normal forwards;
}

.quote-container > img {
  height: 100%;
  object-fit: cover;
  animation: animation 900ms ease 0s 1 normal forwards;
}

.left-quote-button {
  cursor: pointer;
  position: absolute;
  right: 90%;
  width: 32px;
}

.left-quote-button > img {
  width: 100%;
}

.right-quote-button {
  cursor: pointer;
  position: absolute;
  left: 90%;
  width: 32px;
}

.right-quote-button > img {
  width: 100%;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  .title-and-quote {
    gap: 7vh;
  }
  .title-and-quote > div:nth-child(1) > img {
    display: none;
  }
  .quote-container {
    width: 70vw;
    height: 20vh;
    height: 20svh;
    text-transform: none;
  }
  .text-container {
    height: 54vh;
    height: 54svh;
  }
  .left-quote-button {
    cursor: pointer;
    position: absolute;
    right: 90%;
    width: 18px;
  }
  
  .right-quote-button {
    cursor: pointer;
    position: absolute;
    left: 90%;
    width: 18px;
  }
}
