@import "node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

@font-face {
  font-family: "Felix Titling";
  src: url('./assets/fonts//FELIXTI.TTF');
}

:root {
  --white: #EFEFEF;
}

body {
  font-family: "Libre Baskerville" !important;
  background-color: black;
}

.libre-baskerville-regular {
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-style: normal;
}

.libre-baskerville-bold {
  font-family: "Libre Baskerville", serif;
  font-weight: 700;
  font-style: normal;
}

.libre-baskerville-regular-italic {
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-style: italic;
}

.felix-font-family {
  font-family: "Felix Tilting", serif;
}

.cursor-pointer {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #222;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(73, 72, 72);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(73, 72, 72);
}

.noScrollBar::-webkit-scrollbar {
  scrollbar-width: 4px;
  /* for Chrome, Safari, and Opera */
}

.noScrollBar {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

@keyframes fadein {
  from {
    opacity: 0;
    z-index: 0;
    transform: scale3d(0.8);
  }

  to {
    opacity: 1;
    z-index: 10;
    transform: scale3d(1);
  }
}

.faded-underline {
  height: 1px;
  min-width: 134px;
  width: 100%;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 14%, rgba(255, 255, 255, 0) 100%);
  margin-top: 10px;
}

.faded-underline-animated {
  position: absolute;
  top: 110%;
  left: 0px;
  height: 1px;
  width: 100%;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 14%, rgba(255, 255, 255, 0) 100%);
  animation: fadein 700ms ease-in-out 0s 1 normal forwards;
  margin-top: 10px;
}

@keyframes fade_in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade_out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fade_out 700ms ease forwards !important;
}

.fade-in {
  animation: fade_in 700ms ease forwards;
}

.text1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: 1.25px;
}

.text2 {
  font-weight: 400;
  font-size: 32px;
  line-height: 44.8px;
  letter-spacing: 7px;
}

.text3 {
  font-weight: 400;
  font-size: 22.04px;
  line-height: 30.86px;
  letter-spacing: 4.82px;
}

.text4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19.88px;
  letter-spacing: 20px;
}

.text5 {
  font-weight: 400;
  font-size: 11.74px;
  line-height: 16.44px;
  letter-spacing: 2.57px;
}

.text6 {
  font-weight: 700;
  font-size: 18.32px;
  line-height: 25.64px;
  letter-spacing: 1.43px;
}

.text7 {
  font-weight: 400;
  font-size: 18.32px;
  line-height: 25.64px;
  letter-spacing: 1.43px;
  text-align: center;
}

.text8 {
  font-weight: 400;
  font-size: 12px;
  line-height: 9.8px;
  letter-spacing: 0.35px;
  text-align: center;
}

.footer_heading_text {
  font-weight: 400;
  font-size: 11.57px;
  line-height: 16.2px;
  letter-spacing: 2.53px;
  text-align: center;
}

.footer_body_text {
  font-weight: 400;
  font-size: 11.91px;
  line-height: 16.67px;
  letter-spacing: 0.93px;
  text-align: center;
}

.confluence_text {
  color: rgba(239, 239, 239, 1);
  /* Text color */
  text-shadow:
    0.4px 0 0 rgba(215, 179, 0, 0.3),
    /* Right */
    -0.4px 0 0 rgba(215, 179, 0, 0.3),
    /* Left */
    0 0.4px 0 rgba(215, 179, 0, 0.3),
    /* Bottom */
    0 -0.4px 0 rgba(215, 179, 0, 0.3);
  /* Top */
}

.text-black {
  color: black;
}

.bg-black {
  background: black;
}

@keyframes slide_down {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}

.error-message {
  font-size: 10px;
  color: red;
  letter-spacing: 1px;
  transform-origin: top;
  animation: slide_down 500ms ease 0s 1 normal forwards;
}

@media screen and (max-width: 991px) {}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 479px) {
  .text1 {
    font-size: 9.12px;
    line-height: 12.77px;
    letter-spacing: 0.81px;
  }

  .text2 {
    font-weight: 400;
    font-size: 10.91px;
    line-height: 15.27px;
    letter-spacing: 2.39px;
  }

  .text3 {
    font-weight: 400;
    font-size: 10.91px;
    line-height: 12.77px;
    letter-spacing: 0.81px;
  }

  .text6 {
    font-weight: 700;
    font-size: 10.15px;
    line-height: 14.21px;
    letter-spacing: 0.79px;
  }

  .text7 {
    font-weight: 400;
    font-size: 10.15px;
    line-height: 14.21px;
    letter-spacing: 0.79px;
    text-align: center;
  }

  .text8 {
    font-weight: 400;
    font-size: 7px;
    line-height: 9.8px;
    letter-spacing: 0.35px;
    text-align: center;
  }

  .faded-underline {
    height: 1px;
    min-width: 101px;
    width: 100%;
    margin-top: 5px;
  }

  .faded-underline-animated {
    height: 1px;
    width: 100%;
    margin-top: 5px;
  }
}