.container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: var(--white);
  z-index: 1001;
  transition: opacity 1s ease-in-out;
}

.image-container {
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  height: 24px;
  width: 29px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 10px;
  height: 10px;
}

.sound-indicator {
  position: absolute;
  left: calc(100vw - 70px);
  opacity: 0.4;
}

p {
  margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  .container {
    left: 50%;
    transform: translate(-50%);
  }
  .image-container {
    height: 18px;
    width: 21.75px;
    border-radius: 5.25px;
  }
  .image-container img {
    width: 7px;
    height: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sound-indicator {
    position: static;
    opacity: 1;
  }
}
